<template lang="pug">
v-card(v-if="Object.keys(statement).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ ($t(`statement-clarificationOfData-${Object.keys(statement?.behavior)[0]}`, { id: statement?.id })).toUpperCase() }}
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-progress-linear(v-if="isLoading" indeterminate color="cyan")
  v-card-text
    v-divider
    Info(v-if="statement?.behavior?.viewInfoBlock" :statement="statement")
    Reject(v-else-if="statement?.behavior?.viewRejectBlock" :statement="statement")
    ViewPhotoList(
      v-else-if="statement?.behavior?.viewFilesBlock"
      isHiddenActionsButton
      :sailorDocument="statement"
      documentType="statement")
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { viewDetailedComponent } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
export default {
  components: {
    Info: () => import('./Info.vue'),
    Reject: () => import('./Reject.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons.vue')
  },
  data () {
    return {
      digitalizationDocumentISC: {},
      viewDetailedComponent,
      controlButtons: [
        {
          id: 3,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.statement, 'viewInfoBlock'),
          checkAccess: () => checkAccess('backoffice'),
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 4,
          name: 'mdi-file-document-check-outline',
          action: () => this.changeStatusStatement(),
          checkAccess: () => checkAccess('backoffice') && ![STATUSES_STATEMENT_CONSTANTS.clarificationOfData.rejected,
            STATUSES_STATEMENT_CONSTANTS.clarificationOfData.approved].includes(this.statement.status_document),
          color: 'success',
          tooltip: 'documentIconControl.changeStatus',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: 'success', outlined: false }
        },
        {
          id: 5,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.statement, 'viewFilesBlock'),
          checkAccess: () => checkAccess('backoffice'),
          color: '#42627e',
          tooltip: 'documentIconControl.files',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 6,
          name: 'mdi-file-document-remove-outline',
          action: () => viewDetailedComponent(this.statement, 'viewRejectBlock'),
          checkAccess: () => checkAccess('backoffice') && ![STATUSES_STATEMENT_CONSTANTS.clarificationOfData.rejected,
            STATUSES_STATEMENT_CONSTANTS.clarificationOfData.approved].includes(this.statement.status_document),
          color: 'red',
          tooltip: 'documentIconControl.rejectStatement',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: 'red', outlined: false }
        },
        {
          id: 7,
          name: 'mdi-close',
          action: () => this.$router.push({ name: 'clarification-of-data', query: { ...this.$route.query } }),
          checkAccess: () => checkAccess('backoffice'),
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      statement: state => state.statement.clarificationOfDataById,
      isLoading: state => state.statement.isLoading,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getClarificationOfDataById', 'updateClarificationOfDataById']),
    async getData () {
      await this.getClarificationOfDataById({ ...this.$route.params })
    },
    changeStatusStatement () {
      const { documentID } = this.$route.params
      this.$swal({
        title: this.$t('Do you sure that want to change a status the statement', { statusDocument: this.getDirectoryObject({ value: 'statuses', id: STATUSES_STATEMENT_CONSTANTS.clarificationOfData.approved })[this.nameLang] }),
        icon: 'info',
        buttons: [this.$t('no'), this.$t('yes')]
      }).then((confirm) => {
        if (confirm) {
          this.updateClarificationOfDataById({ id: documentID, body: { status_document: STATUSES_STATEMENT_CONSTANTS.clarificationOfData.approved } })
        }
      })
    }
  }
}
</script>
